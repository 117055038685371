import {Button, Card, Col, Collapse, Descriptions, Row, Show, Tag} from '@pankod/refine-antd';
import React, {useEffect, useState} from 'react';
import BanUserButton from '../../components/buttons/BanUserButton';
import {EModerationStatus, IFile, IPerformer, IPerformerData, IPhotoFile, IService, ITariff} from '../../common/types';
import ModerateButtons from '../../components/buttons/ModerateButtons';
import {useCustomMutation, useOne, useResource} from '@pankod/refine-core';
import {Image, Space, Typography} from 'antd';
import ServiceCards from '../../components/performers/ServiceCards';
import TariffModal from '../../components/performers/TariffModal';
import {MEETING_PLACE_TYPE} from '../../common/constants';
import {LoaderScreen} from '../../components/layout/LoaderScreen';
import PerformerInfo from './components/PerformerInfo';
import {Link} from '@pankod/refine-react-router-v6';
import {settings} from '../../common/settings';
import ModerateEliteButton from "../../components/buttons/ModerateEliteButton";

const {Title} = Typography;


export const PerformerShow = () => {
  const {id} = useResource()
  const [performer, setPerformer] = useState<IPerformer>()
  const [images, setImages] = useState<IPhotoFile[]>([])
  const [videos, setVideos] = useState<IFile[]>([])
  const [services, setServices] = useState<IService[]>([])
  const [tariffs, setTariffs] = useState<ITariff[]>([])
  const [selectedTariff, setSelectedTariff] = useState<ITariff | null>(null)
  const [hideModeration, setHideModeration] = useState<boolean>(false)
  const {mutate} = useCustomMutation()

  const {data: response, isLoading, refetch} = useOne<IPerformerData>({
    resource: 'performers',
    dataProviderName: 'userProvider',
    id: id || '',
    errorNotification: {
      type: 'error',
      description: 'Ошибка',
      message: 'Не удалось получить информацию о рекламодателе',
    },
  })

  const videoVerifyAction = (id: string, isVerify: boolean) => mutate({
    url: `${settings.api.url}/verification/video/${id}/${isVerify ? '' : 'un'}verify`,
    method: 'patch',
    values: {}
  }, {
    onSuccess: () => refetch()
  })

  useEffect(() => {
    if (response) {
      const moderationStatus = response.data.data.moderationStatus
      setPerformer(response.data.data)
      setImages(response.data?.images?.sort((i) => i.isBlur ? -1 : 1) || [])
      setVideos(response.data?.videos || [])
      setServices(response.data?.services || [])
      setTariffs(response.data?.tariffs || [])
      setHideModeration([EModerationStatus.ACCEPTED, EModerationStatus.DECLINED].includes(moderationStatus))
    }
  }, [response])

  if (isLoading) {
    return <LoaderScreen/>
  }

  return (
    <Show
      canDelete={false}
      breadcrumb={false}
      headerProps={{
        title: `Просмотр рекламодателя ${performer?.nickname || ''}`
      }}
      headerButtons={(
        <BanUserButton
          section="performers"
          id={performer?.id}
          isBlocked={performer?.isBlocked || false}
          onSuccess={refetch}
        />
      )}
    >
      <TariffModal
        selectedTariff={selectedTariff}
        setSelectedTariff={setSelectedTariff}
      />

      <Row gutter={[24, 12]}>
        <Col xs={24} md={24}>
          {(!hideModeration && performer?.id) && (
              <div style={{marginBottom: 20}}>
                <Title level={4}>
                  Верифицировать профиль рекламодателя
                </Title>

                <ModerateButtons
                    id={performer.id}
                    isAccept={performer.isModerated || false}
                    onSuccess={refetch}
                />
              </div>
          )}

          <div style={{marginBottom: 20}}>
            <Title level={4}>
              <Space>
                Верификационное видео
                {performer?.videoVerification?.id && (
                    <Tag color={performer?.videoVerification?.isVerified ? 'green' : 'red'}>
                      {performer?.videoVerification?.isVerified ? 'Верифицированно' : 'Неверифицированно'}
                    </Tag>
                )}
              </Space>
            </Title>

            {performer?.videoVerification?.code && (
                <Tag style={{marginBottom: 15}}>Код верификации: <b>{performer?.videoVerification?.code}</b></Tag>
            )}

            {performer?.videoVerification?.id ? (
                <div style={{display: 'flex', flexDirection: 'column', gap: 12, maxHeight: 320, aspectRatio: '16/9'}}>
                  <video controls={true}>
                    <source src={performer?.videoVerification?.video?.url} type="video/mp4"/>
                  </video>

                  <Space>
                    <Button type="primary" onClick={() => videoVerifyAction(performer!!.videoVerification!!.id, true)}>
                      Принять
                    </Button>
                    <Button danger onClick={() => videoVerifyAction(performer!!.videoVerification!!.id, false)}>
                      Отклонить
                    </Button>
                  </Space>
                </div>
            ) : (
                <p>Не загруженно</p>
            )}
          </div>

          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel key={1} header={"Элита"}>
              <div style={{marginBottom: 20}}>
                {(performer?.moderationStatusElite === EModerationStatus.NOTMODERATED) && (
                <div>
                  <Title level={4}>
                    Пользователь {performer?.nickname} пока не отправил заявку на статус "Элита UWU"
                  </Title>
                </div>)}
                {(performer?.moderationStatusElite === EModerationStatus.SENT) && (
                    <div>
                      <Title level={4}>
                        Пользователь {performer?.nickname} ждет рассмотрения заявки на статус "Элита UWU"
                      </Title>
                    </div>)}
                {(performer?.moderationStatusElite === EModerationStatus.DECLINED) && (
                    <div>
                      <Title level={4}>
                        Заявка была отклонена
                      </Title>
                    </div>)}
                {(performer?.moderationStatusElite === EModerationStatus.ACCEPTED) && (
                    <div>
                      <Title level={4}>
                        Заявка была принята
                      </Title>
                    </div>)}
                <ModerateEliteButton id={performer?.id} isAccept={!performer?.isElite}
                                     onSuccess={refetch} eliteStatus={performer?.moderationStatusElite}></ModerateEliteButton>
            </div>

          </Collapse.Panel>
            <Collapse.Panel
                header="Рекламодатель"
                key={2}
            >
              <PerformerInfo performer={performer}>
                <Descriptions.Item label="Подписка">
                  {performer?.subscription || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Переход к арбитражам">
                  <Link
                      to={`/disputes?filters[0][field]=advertiser.id&filters[0][operator]=eq&filters[0][value]=${performer?.id}`}>
                    <Button size="small" type="primary">
                      Перейти
                    </Button>
                  </Link>
                </Descriptions.Item>

                <Descriptions.Item label="Адрес">
                  {performer?.address || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Описание адреса">
                  {performer?.addressDescription || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Часовой пояс">
                  {performer?.timezone || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Позиция в рейтинге">
                  {performer?.numberOfRatings || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Баланс">
                  {performer?.balance || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Салон">
                  {performer?.salon?.name || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Место встречи">
                  <p>Описание: {performer?.meetingPlace?.meetingPlace?.description?.RU || '-'}</p>
                  <p>Описание (en): {performer?.meetingPlace?.meetingPlace?.description?.EN || '-'}</p>
                  <p>Тип: {MEETING_PLACE_TYPE.find(mpt => mpt.key === performer?.meetingPlace?.placeType)?.name || '-'}</p>
                  <p>В другой стране: {performer?.meetingPlace?.visitToOtherCountry ? 'Да' : 'Нет'}</p>
                  <p>В другом городе: {performer?.meetingPlace?.visitToOtherCity ? 'Да' : 'Нет'}</p>
                  <p>В апартаментах: {performer?.meetingPlace?.visitToApartment ? 'Да' : 'Нет'}</p>
                  <p>Посторонние в апартаментах: {performer?.meetingPlace?.strangersInApartment ? 'Да' : 'Нет'}</p>
                  <p>В отеле: {performer?.meetingPlace?.visitToHotel ? 'Да' : 'Нет'}</p>
                  <p>В офисе: {performer?.meetingPlace?.visitToOffice ? 'Да' : 'Нет'}</p>
                  <p>В сауне: {performer?.meetingPlace?.visitToSauna ? 'Да' : 'Нет'}</p>
                  <p>В машине: {performer?.meetingPlace?.meetingInCar ? 'Да' : 'Нет'}</p>
                </Descriptions.Item>

                <Descriptions.Item label="Владение языками">
                  {performer?.languageLevels?.language || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Удобное время для общения">
                  {performer?.convenientTimeToChat || '-'}
                </Descriptions.Item>

                {!!tariffs.length && (
                    <Descriptions.Item label="Тарифы">
                      <Row gutter={[16, 16]}>
                        {tariffs.map(tariff => {
                          const defaultTariff = tariff?.tariff
                          const r = tariff.priceRub?.toString()
                          const u = tariff.priceUsd.toString()
                          const priceRub = tariff.priceRub > 0 ? r.slice(0, r.length - 2) + `,${r.slice(r.length - 2, r.length)}` : 0
                          const priceUsd = tariff.priceUsd > 0 ? u.slice(0, u.length - 2) + `,${u.slice(u.length - 2, u.length)}` : 0
                          const duration = defaultTariff ? tariff?.tariff?.duration : tariff?.duration
                          const durationTime = duration < 60 ? `${duration} м` : `${duration / 60} ч`
                          const pdTime = tariff.preparationDuration < 60 ? `${tariff.preparationDuration} м` : `${tariff.preparationDuration / 60} ч`
                          const meetingPlace = tariff?.meetingPlace?.description
                          return (
                              <Col span={8} key={tariff.id}>
                                <Card
                                    title={
                                      `${defaultTariff?.name?.RU ?? tariff.nameRu} (${defaultTariff?.name.EN ?? tariff.nameEn})`
                                    }
                                    extra={(
                                        <Button type="primary" size="small" onClick={() => setSelectedTariff(tariff)}>
                                          Подробнее
                                        </Button>
                                    )}
                                >
                                  {tariff?.cover?.urls && (
                                      <h5>
                                        Изображение:
                                        <div>
                                          <Image
                                              src={tariff?.cover?.urls['100']}
                                              className="performer-image"
                                              preview={{
                                                src: tariff.cover.url
                                              }}
                                          />
                                        </div>
                                      </h5>
                                  )}
                                  {defaultTariff?.icon && (
                                      <h5>
                                        Иконка:
                                        <div className="svg-icon"
                                             dangerouslySetInnerHTML={{__html: defaultTariff.icon}}/>
                                      </h5>
                                  )}
                                  <h5>Кол-во окончаний: {tariff?.cumCount || '-'}</h5>
                                  <h5>Время ожидания: {pdTime}</h5>
                                  <h5>Длительность: {durationTime}</h5>
                                  <h5>Место: {meetingPlace?.RU || '-'} {meetingPlace?.EN ? `(${meetingPlace.EN})` : ''}</h5>
                                  <div>
                                    <h5>Стоимость:</h5>
                                    <Tag color="green">{priceRub} P</Tag>
                                    <Tag color="green">{priceUsd} USD</Tag>
                                  </div>
                                </Card>
                              </Col>
                          )
                        })}
                      </Row>
                    </Descriptions.Item>
                )}

                {!!services.length && (
                    <Descriptions.Item label="Услуги">
                      <Row gutter={[16, 16]}>
                        <ServiceCards services={services}/>
                      </Row>
                    </Descriptions.Item>
                )}
              </PerformerInfo>
            </Collapse.Panel>

            <Collapse.Panel
                header="Личность"
                key={3}
            >
              <Descriptions
                  column={1}
                  bordered={true}
                  layout="vertical"
                  size="small"
              >
                <Descriptions.Item label="Пол">
                  {performer?.gender?.name?.RU || '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Возраст">
                  {(!performer?.minClientAge && !performer?.maxClientAge) && '-'}
                  {performer?.minClientAge ? ` от ${performer?.maxClientAge}` : ''}
                  {performer?.minClientAge ? ` до ${performer?.maxClientAge}` : ''}
                </Descriptions.Item>

                <Descriptions.Item label="Описание анкеты">
                  {performer?.descriptionRu || performer?.descriptionEn || '-'}
                </Descriptions.Item>

                {!!images.filter(i => !i.isBlur).length && (
                    <Descriptions.Item
                        label="Фотографии"
                        className="image-section"
                    >
                      <Image.PreviewGroup>
                        {images.filter(i => !i.isBlur).map(image => (
                            <Image
                                key={image.file.url}
                                width={200}
                                height={200}
                                style={{objectFit: 'cover'}}
                                src={image?.file?.urls['500'] ?? image.file.url}
                                preview={{src: image.file.url}}
                            />
                        ))}
                      </Image.PreviewGroup>
                    </Descriptions.Item>
                )}

                {!!images.filter(i => i.isBlur).length && (
                    <Descriptions.Item
                        label="Фотографии (c блюром)"
                        className="image-section"
                    >
                      <Image.PreviewGroup>
                        {images.filter(i => i.isBlur).map(image => (
                            <Image
                                key={image.file.url}
                                width={200}
                                height={200}
                                style={{objectFit: 'cover'}}
                                src={image?.file?.urls['500'] ?? image.file.url}
                                preview={{src: image.file.url}}
                            />
                        ))}
                      </Image.PreviewGroup>
                    </Descriptions.Item>
                )}

                <Descriptions.Item
                    label="Видео"
                    className="video-section"
                >
                  {!!videos.length ? videos.map(video => (
                      <div key={video.url} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        <video controls={true}>
                          <source src={video.url} type="video/mp4"/>
                        </video>
                        <Space>
                          <Button type="primary" onClick={() => videoVerifyAction(video.id, true)}>
                            Принять
                          </Button>
                          <Button danger onClick={() => videoVerifyAction(video.id, false)}>
                            Отклонить
                          </Button>
                        </Space>
                      </div>
                  )) : '-'}
                </Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>

            <Collapse.Panel
                header="Внешность"
                key={4}
            >
              <Descriptions
                  column={1}
                  bordered={true}
                  layout="vertical"
                  size="small"
              >
                {performer?.breastSize && (
                    <Descriptions.Item label="Размер груди">
                      {performer?.breastSize}
                    </Descriptions.Item>
                )}

                {performer?.siliconeBreast !== null && (
                    <Descriptions.Item label="Силиконовая грудь">
                      {performer?.siliconeBreast ? 'Да' : 'Нет'}
                    </Descriptions.Item>
                )}

                {performer?.penisLengthCm && (
                    <Descriptions.Item label="Длина члена">
                      {performer?.penisLengthCm || '-'}
                    </Descriptions.Item>
                )}

                {performer?.penisGirthCm && (
                    <Descriptions.Item label="Обхват члена">
                      {performer?.penisGirthCm || '-'}
                    </Descriptions.Item>
                )}

                {performer?.heightCm && (
                    <Descriptions.Item label="Высота">
                      {performer?.heightCm || '-'}
                    </Descriptions.Item>
                )}

                {performer?.weightKg && (
                    <Descriptions.Item label="Вес">
                      {performer?.weightKg || '-'}
                    </Descriptions.Item>
                )}

                {performer?.piercing !== null && (
                    <Descriptions.Item label="Есть пирсинг">
                      {performer?.piercing ? 'Да' : 'Нет'}
                    </Descriptions.Item>
                )}

                {performer?.tattoos !== null && (
                    <Descriptions.Item label="Есть тату">
                      {performer?.tattoos ? 'Да' : 'Нет'}
                    </Descriptions.Item>
                )}

                {performer?.physique?.name?.RU && (
                    <Descriptions.Item label="Телосложение">
                      {performer?.physique?.name?.RU || '-'}
                    </Descriptions.Item>
                )}

                {performer?.smokingAttitude?.description?.RU && (
                    <Descriptions.Item label="Курение">
                      {performer?.smokingAttitude?.description?.RU || '-'}
                    </Descriptions.Item>
                )}

                {performer?.alcoholAttitude?.description?.RU && (
                    <Descriptions.Item label="Алкоголь">
                      {performer?.alcoholAttitude?.description?.RU || '-'}
                    </Descriptions.Item>
                )}

                {performer?.eyeColor?.name?.RU && (
                    <Descriptions.Item label="Цвет глаз">
                      {performer?.eyeColor?.name?.RU || '-'}
                    </Descriptions.Item>
                )}

                {performer?.hairColor?.name?.RU && (
                    <Descriptions.Item label="Цвет волос">
                      {performer?.hairColor?.name?.RU || '-'}
                    </Descriptions.Item>
                )}

                {performer?.intimateHaircut?.description?.RU && (
                    <Descriptions.Item label="Интимная стрижка">
                      {performer?.intimateHaircut?.description?.RU || '-'}
                    </Descriptions.Item>
                )}

                {performer?.ethnos?.name?.RU && (
                    <Descriptions.Item label="Этнос">
                      {performer?.ethnos?.name?.RU || '-'}
                    </Descriptions.Item>
                )}

                {performer?.ageRange && (
                    <Descriptions.Item label="Возрастные ограничения">
                      {(!performer?.ageRange?.min && !performer?.ageRange?.max) && '-'}
                      {performer?.ageRange?.min ? ` от ${performer?.ageRange?.min}` : ''}
                      {performer?.ageRange?.max ? ` до ${performer?.ageRange?.max}` : ''}
                    </Descriptions.Item>
                )}
              </Descriptions>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </Show>
  )
}

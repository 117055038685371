import React, {FC} from 'react';
import {useCustomMutation} from '@pankod/refine-core';
import {IBanButtonProps, IModerateButtonProps, ISalon} from '../../common/types';
import {settings} from '../../common/settings';
import {Button} from '@pankod/refine-antd';
import {LockOutlined, UnlockOutlined} from '@ant-design/icons';
import {Space} from 'antd';

const ModerateButtons: FC<IModerateButtonProps> = ({ onSuccess, id, isAccept }) => {
  const {mutate} = useCustomMutation<ISalon>()

  const handleModerate = (id: string, isAccept: boolean) => mutate({
    url: `${settings.api.url}/users/performers/${id}/${isAccept ? 'accept' : 'decline'}`,
    method: 'patch',
    values: {}
  }, {
    onSuccess: onSuccess
  })

  return (
    <Space>
      <Button
        type="primary"
        onClick={() => id ? handleModerate(id, true) : null}
      >
        Принять
      </Button>
      <Button onClick={() => id ? handleModerate(id, false) : null}>
        Отклонить
      </Button>
    </Space>
  )
}

export default ModerateButtons

import React from 'react';

export const settings = {
  project: {
    name: process.env.REACT_APP_PROJECT_NAME || 'Project22'
  },
  auth: {
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    userType: 'userType',
    remember: 'remember',
  },
  api: {
    url: process.env.REACT_APP_APP_API_URL ? process.env.REACT_APP_APP_API_URL + '/admin' : '',
    backendUrl: process.env.REACT_APP_APP_API_URL ? process.env.REACT_APP_APP_API_URL : '',
    methodsWithBody: ['POST', 'PUT', 'PATCH', 'DELETE'],
  }
}
